import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import SideBar from '../components/SideBar/SideBar'
import SideBarContent from '../components/SideBar/SideBarContent'
import ArticleHero from '../components/Article/ArticleHero'
import Article from '../components/Article/Article'
import ArticleSimilar from '../components/Article/ArticleSimilar'

const CryptoTemplate = ({ data }) => {

  const { title, featuredImage, content, recommendedPosts, categories, seo } = data.allWpCryptocurrency.nodes[0]

  return (
    <Layout seo={ seo }>
      <ArticleHero
        heroTitle={ title }
        heroImage={ featuredImage }
        heroCategory={ categories.nodes }
        breadcrumbs={ seo.breadcrumbs }
      />
      <Container variant="aside">
        <Article>{ parse(content) }</Article>
        <SideBar>
          <SideBarContent title="Рекомендуем" variant="sticky">
            <ArticleSimilar similar={ recommendedPosts.recommendedPosts } />
          </SideBarContent>
        </SideBar>
      </Container>
    </Layout>
  )
}

export default CryptoTemplate


export const postCryptoQuery = graphql `
  query CryptoQuery($slug: String) {
    allWpCryptocurrency(filter: {slug: {eq: $slug}}) {
      nodes {
        categories {
          nodes {
            name
          }
        }
        id
        slug
        title
        uri
        content
        seo {
          canonical
          metaDesc
          opengraphDescription
          opengraphModifiedTime
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          title
          breadcrumbs {
            text
            url
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 1600
                  height: 460
                  jpgOptions: {progressive: true}
                  webpOptions: {quality: 70}
                )
              }
            }
          }
        }
        recommendedPosts {
          recommendedPosts {
            ... on WpStock {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
            ... on WpTrading {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
            ... on WpEstate {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
            ... on WpCryptocurrency {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
            ... on WpForex {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
            ... on WpNewbie {
              id
              title
              uri
              postThumb {
                postThumb {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`